import * as React from "react"
import SEO from "components/seo"
import { ClientsT, PageContextT, HeaderLinkT } from "../types/Common"
import PageBuilderWrapper from "../components/PageBuilder/PageBuilderWrapper"
import Layout from "../components/organisms/Layout"

type Props = {
  pageContext: PageContextT
  clients: ClientsT
  location: Location
}

const IndexPage: React.ComponentType<Props> = (props: Props) => {
  const {
    pageContext: {
      localizedPage,
      page,
      mainHeaderLinks,
      otherHeaderLinks,
      navigationLinks,
      footerSections,
      socialLinks,
      commonLinks,
      uploadedFiles,
      products,
      contactForms,
      blogs,
      clients,
      availablePositions,
      l3,
      configurations,
      mobileHeaderLinks,
      modalClients,
    },
  } = props

  const breadCrumbs = []
  const activePage = otherHeaderLinks.find(link => link.page.id === page.id)
  const isSamePath = (link, pathName) => {
    return link === pathName || link === `${pathName}/`
  }

  if (l3.length) {
    const currentPageL3: HeaderLinkT = l3.find((link: HeaderLinkT) =>
      isSamePath(link.url, props.location.pathname)
    )

    if (currentPageL3) {
      breadCrumbs.push(currentPageL3.url)
      const parent1 = navigationLinks.find(
        (link: HeaderLinkT) => link.id === currentPageL3.parent.id
      )
      breadCrumbs.push(parent1.url)

      const parent2 = mainHeaderLinks.find(
        (link: HeaderLinkT) => link.id === parent1.parent.id
      )
      breadCrumbs.push(parent2.url)
    } else {
      const currentPageL2: HeaderLinkT = navigationLinks.find(
        (link: HeaderLinkT) => isSamePath(link.url, props.location.pathname)
      )

      breadCrumbs.push(currentPageL2.url)

      const parent2 = mainHeaderLinks.find(
        (link: HeaderLinkT) => link.id === currentPageL2.parent.id
      )
      breadCrumbs.push(parent2.url)
    }
  } else {
    const currentPageL2: HeaderLinkT = navigationLinks.find(
      (link: HeaderLinkT) => isSamePath(link.url, props.location.pathname)
    )

    if (currentPageL2) {
      const parent2 = mainHeaderLinks.find(
        (link: HeaderLinkT) => link.id === currentPageL2.parent.id
      )
      if (parent2) {
        breadCrumbs.push(parent2.url)
      }
    }
  }
  const contactRef = React.useRef<HTMLDivElement>(null)
  const executeScroll = () => contactRef.current.scrollIntoView()

  let image = page.SEO?.image
  if (!image) {
    if (page.text[0].type === "HeroBanner") {
      image = page.text[0].props.src
    }
  }
  return (
    <Layout
      mainHeaderLinks={mainHeaderLinks}
      otherHeaderLinks={otherHeaderLinks}
      navigationLinks={navigationLinks}
      footerSections={footerSections}
      socialLinks={socialLinks}
      commonLinks={commonLinks}
      l3={l3}
      configurations={configurations}
      mobileHeaderLinks={mobileHeaderLinks}
      activePage={activePage}
      executeScroll={executeScroll}
      showCtaButton={page.showCtaButton}
      ctaButtonText={page.ctaButtonText}
      localizedPage={localizedPage}
      breadCrumbs={breadCrumbs}
      showInfoBar={page.showInfoBar}
      infoBarText={page.infoBarText}
    >
      <SEO
        title={page.SEO?.title || page.title}
        description={page.SEO?.description}
        canonicalLink={page.SEO?.canonicalLink}
        image={image}
      />
      <PageBuilderWrapper
        text={page.text}
        uploadedFiles={uploadedFiles}
        products={products}
        contactForms={contactForms}
        blogs={blogs}
        clients={clients}
        availablePositions={availablePositions}
        contactRef={contactRef}
        configurations={configurations}
        modalClients={modalClients}
      />
    </Layout>
  )
}

export default IndexPage
